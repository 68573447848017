body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #8f6eb4;
  color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main {
  margin-top: 15vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.gif {
  height: 600px;
  margin-bottom: 50px;
}

.info {
  font-size: 30px;
}

.MuiButton-root {
  font-size: 30px !important;
}

.MuiDialog-paper {
  max-width: none !important;
  width: 800px !important;
}

.makeStyles-root-2 {
  height: 55px !important;
  width: 55px !important;
}

@media (max-width: 3000px) {
  .gif {
    height: 400px;
    margin-bottom: 40px;
  }

  .info {
    font-size: 25px;
  }
  .MuiButton-root {
    font-size: 25px !important;
  }
}

@media (max-width: 2300px) {
  .gif {
    height: 300px;
    margin-bottom: 30px;
  }

  .info {
    font-size: 20px;
  }
  .MuiButton-root {
    font-size: 20px !important;
  }

  .MuiDialog-paper {
    max-width: none !important;
    width: 600px !important;
  }
  
  .makeStyles-root-2 {
    height: 45px !important;
    width: 45px !important;
  }
}

@media (max-width: 1700px) {
  .gif {
    height: 200px;
    margin-bottom: 20px;
  }

  .info {
    font-size: 16px;
  }
  .MuiButton-root {
    font-size: 16px !important;
  }

  .MuiDialog-paper {
    max-width: none !important;
    width: 500px !important;
  }
  
  .makeStyles-root-2 {
    height: 35px !important;
    width: 35px !important;
  }
}


